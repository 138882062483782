import React, { useEffect, useState } from 'react';
import UserService from 'services/user.service';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import { useSelector } from 'react-redux'

const userService = new UserService();

const SideBarContent = () => {
  const [navigationMenus, setNavigationMenus] = useState([]);
  const userUid = useSelector((state) => state.auth.authUser);
  useEffect(() => {
    const userSub = userService
    .getByUserUid(userUid)
    .subscribe(user => {
        const menuItems = [];
        const tdbItems = {
          name: 'sidebar.section.dashboards',
          type: 'section',
          children: []
        };

        if(user.applications.ims) {
          tdbItems.children.push({
            name: 'pages.dashboardIms',
            type: 'item',
            link: '/app/dashboardIms'
          });
        }
        if(user.applications.weather) {
          tdbItems.children.push({
            name: 'pages.dashboardWeather',
            type: 'item',
            link: '/app/dashboardWeather'
          });
        }
        menuItems.push(tdbItems)

        setNavigationMenus(menuItems);
      })
    ;

    // unsubscribe on destroy
    return () => {
      userSub.unsubscribe();
    };
  }, [])

  return (
    <CustomScrollbars className=" scrollbar">
      {/* <Link className="app-logo mr-2 d-none d-sm-block" to="/">
        <img src={require("assets/images/logo.png")} />
      </Link> */}
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

export default SideBarContent;
