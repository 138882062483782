import firebase from 'firebase';

import {
  initializeApp,
  auth as authFirebase,
  GoogleAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider
} from 'firebase/app';

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// // Initialize Firebase
initializeApp(firebaseConfig);
const auth = authFirebase();

const googleAuthProvider = GoogleAuthProvider;
const facebookAuthProvider = FacebookAuthProvider;
const githubAuthProvider = GithubAuthProvider;
const twitterAuthProvider = TwitterAuthProvider;

const database = firebase.database();
const firestore = firebase.firestore();
const functions = firebase.functions();

export {
    auth,
    database,
    googleAuthProvider,
    githubAuthProvider,
    facebookAuthProvider,
    twitterAuthProvider,
    firebaseConfig,
    firestore,
    firebase,
    functions
};