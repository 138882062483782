import { firestore } from '../firebase/firebase';
import { docData } from 'rxfire/firestore';

class UserService {

  constructor() {
    this.firestore = firestore;
  }

  /**
   * Get list of locations filtered by user
   *
   * @param {String} userUid
   * @returns
   * @memberof UserService
   */
  getByUserUid(userUid) {
    const userRef = this.firestore.collection('users').doc(userUid);

    return docData(userRef, 'id');
  }

}


export default UserService