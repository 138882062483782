import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';
import UserService from 'services/user.service';
import asyncComponent from '../../util/asyncComponent';
import { useSelector } from 'react-redux'
import { has } from 'lodash';
import { Container, CircularProgress } from '@material-ui/core'

const userService = new UserService();
export const NoMatch = () => {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

const Routes = ({ match }) => {
  const userUid = useSelector((state) => state.auth.authUser);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userSub = userService.getByUserUid(userUid).subscribe((userFound) => {
      setUser(userFound);
      setLoading(false);
    });
    // unsubscribe on destroy
    return () => {
      userSub.unsubscribe();
    };
  }, []);

  const showWeatherApp = has(user, 'applications.weather') && user.applications.weather;
  const showImsApp = has(user, 'applications.ims') && user.applications.ims;

  if (loading) {
    return (
      <Container style={{ textAlign: "center", paddingTop: 160 }}>
        <CircularProgress color="secondary" />
      </Container>
    );
  }

  return (
    <Switch>
      {showImsApp && <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./DashboardPage/dashboard'))} />}
      {showWeatherApp && <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./DashboardPage/dashboardWeather'))} />}
      {showWeatherApp && <Route path={`${match.url}/dashboardWeather`} component={asyncComponent(() => import('./DashboardPage/dashboardWeather'))} />}
      {showWeatherApp && <Route path={`${match.url}/locations/:id/weatherStationHistory`} component={asyncComponent(() => import('./WeatherStationPage/history'))} />}
      {showImsApp && <Route path={`${match.url}/dashboardIms`} component={asyncComponent(() => import('./DashboardPage/dashboard'))} />}
      {showImsApp && <Route path={`${match.url}/locations/:id/view`} component={asyncComponent(() => import('./LocationPage/location-view'))} />}
      {showImsApp && <Route path={`${match.url}/locations/:id/schedules/add`} component={asyncComponent(() => import('./SchedulePage/schedule-add'))} />}
      {showImsApp && <Route path={`${match.url}/locations/:id/valves`} component={asyncComponent(() => import('./LocationPage/location-valves'))} />}

      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
}



export default withRouter(Routes);

